import React, { Component } from "react";

export const ProjectContext = React.createContext();

class ProjectProvider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            specifications: false,
            creative: false,
            hosting: false,
            projectType: 'contact'
        }
    }

    handleChangeSpecifications = () => {
        this.setState({ specifications: !this.state.specifications});
    };

    handleChangeCreative = () => {
        this.setState({ creative: !this.state.creative});
    };

    handleChangeHosting = () => {
        this.setState({ hosting: !this.state.hosting});
    };

    handleChangeProjectType = (type) => {
        this.setState({ projectType: type});
    };

    render() {
        const contextValue = {
            data: this.state,
            handleChangeSpecifications: this.handleChangeSpecifications,
            handleChangeCreative: this.handleChangeCreative,
            handleChangeHosting: this.handleChangeHosting,
            handleChangeProjectType: this.handleChangeProjectType
        };

        return (
            <ProjectContext.Provider value={contextValue}>
                {this.props.children}
            </ProjectContext.Provider>
        )
    }
}

export default ProjectProvider;
