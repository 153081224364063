import React, {Suspense} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import {createMuiTheme, ThemeProvider, responsiveFontSizes} from "@material-ui/core/styles";

import { Trans } from 'react-i18next';

import MainStage from "./components/main-stage";


import './css/app.scss';
import ScrollToTop from "./helpers/scroll-to-top";
import ProjectProvider from "./contexts/project-context";
import i18n from './helpers/i18n';

const history = createBrowserHistory();

let theme = createMuiTheme ({
    palette: {
        primary: {main: '#ff0000'},
        secondary: {main: '#e73e3e'}
    },
    typography: {
        fontFamily: [
            'Khand',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '3rem',
            fontWeight: 300,
            textTransform: 'uppercase',
            color: '#1d1d1d',
            marginTop: '100px',
            marginBottom: '2vw',
            textAlign: 'left',
        },
        h2: {
            fontSize: '3.5rem',
            fontWeight: 500,
            textTransform: 'none',
            marginBottom: '3vw',
            color: '#1d1d1d'
        },
        h3: {
            fontSize: '1.8rem',
            fontWeight: 700,
            textAlign: 'center',
            margin: '1vw 0',
            color: '#e73e3e'
        },
        body1: {
            padding: '1vw',
            textAlign: 'center',
            fontSize: '1.2rem'
        },
        body2: {
            padding: '2vw',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontStyle: 'italic'
        }
    },
    overrides: {
        MuiPaper: {
            rounded: {
                padding: '20px',
                borderRadius: '10px'
            }
        },
        MuiInputBase: {
            root: {
                padding: 'inherit',
                minHeight: '2vw'
            }
        }
    }
});

theme = responsiveFontSizes(theme);

const CookieConsent = loadable(() => import('react-cookie-consent'));
const Footer = loadable(() => import('./components/footer'));
const Menu = loadable(() => import('./components/menu'));

class App extends React.Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <ProjectProvider>
                    <Router>
                        <ScrollToTop>
                            <Suspense fallback={<div style={{height: 64, width: '100%'}}>Loading...</div>}>
                                <Menu history={history}/>
                            </Suspense>
                            <MainStage/>
                            <Suspense fallback={<div className={'footer-suspense'}>Loading...</div>}>
                                <Footer/>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <CookieConsent
                                    location="bottom"
                                    buttonText={<Trans>cookie_consent.button_label</Trans>}
                                    cookieName="codeItWebsiteCookieConsent"
                                    style={{ background: "#000000", fontFamily: ['Khand', 'Arial', 'sans-serif'].join(','), fontSize: "1rem" }}
                                    buttonStyle={{ color: "#000000", fontSize: "1.2rem", backgroundColor: "#ffffff" }}
                                    expires={150}
                                    contentStyle={{flex: 1}}
                                >
                                    {<Trans>cookie_consent.disclaimer</Trans>} {' '}
                                    <Link to={i18n.language === 'en' ? '/' + this.props.t('routes.cookies_policy') : '/' +i18n.language + '/' + this.props.t('routes.cookies_policy')} style={{color: '#ffffff'}}>
                                        <span style={{ fontSize: "1rem" }}>{<Trans>cookie_consent.know_more</Trans>}</span>
                                    </Link>
                                </CookieConsent>
                            </Suspense>
                        </ScrollToTop>
                    </Router>
                </ProjectProvider>
            </ThemeProvider>
        );
    }
}

export default withTranslation()(App);
