import React, {Suspense} from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withTracker from "../helpers/with-tracker";
import loadable from '@loadable/component'

const NewProject = loadable(() => import('../pages/new-project'));
const ProjectRebuild = loadable(() => import('../pages/project-rebuild'));
const DesignRefresh = loadable(() => import('../pages/design-refresh'));
const ContactUs = loadable(() => import('../pages/contact-us'));
const NotFound = loadable(() => import('../pages/not-found'));
const CookiesPolicy = loadable(() => import('../pages/cookies-policy'));
const WhyUs = loadable(() => import('../pages/why-us'));
const Home = loadable(() => import('../pages/home'));

export default function MainStage() {

    const baseRouteUrl = "/:locale(fr|en)?";
    const {t} = useTranslation();

    return (
        <Suspense fallback={<div style={{height: '10000px'}}>Loading...</div>}>
            <Switch>
                <Route exact path={baseRouteUrl + '/'}  component={withTracker(Home)}/>
                <Route exact path={baseRouteUrl + '/' + t('routes.new_project')} component={withTracker(NewProject)} />
                <Route exact path={baseRouteUrl + '/' + t('routes.project_upgrade')} component={withTracker(ProjectRebuild)} />
                <Route exact path={baseRouteUrl + '/' + t('routes.modernisation')} component={withTracker(DesignRefresh)} />
                <Route exact path={baseRouteUrl + '/' + t('routes.why_us')} component={withTracker(WhyUs)} />
                <Route exact path={baseRouteUrl + '/' + t('routes.contact_us')} component={withTracker(ContactUs)} />
                <Route exact path={baseRouteUrl + '/' + t('routes.cookies_policy')} component={withTracker(CookiesPolicy)} />
                <Route path="*" component={withTracker(NotFound)} />
            </Switch>
        </Suspense>
    );
}
