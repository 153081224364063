import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from '../translations/en/common';
import common_fr from '../translations/fr/common';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        defaultNS: 'common',
        resources: {
            en: {
                common: common_en
            },
            fr: {
                common: common_fr
            }
        },
        whitelist: ['en', 'fr'],
        fallbackLng: ['en'],
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        }
    });

export default i18n;
